@font-face {
  font-family: "Replica-Light";
  src: local("Replica-Light"),
    url(./assets/fonts/ReplicaStdLight.otf) format("opentype");
}
@font-face {
  font-family: "Replica-Regular";
  src: local("Replica-Regular"),
    url(./assets/fonts/ReplicaStdRegular.otf) format("opentype");
}
@font-face {
  font-family: "Replica-Bold";
  src: local("Replica-Bold"),
    url(./assets/fonts/ReplicaStdBold.otf) format("opentype");
}

/* Global styles */

body {
  font-family: Replica-Regular, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.header {
  font-family: Replica-Bold, Arial, sans-serif !important;
}
.sub.header {
  font-family: Replica-Regular, Arial, sans-serif !important;
}

h1,
.h1 {
  font-size: 37px !important;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  color: black !important;
}
a {
  color: #0084d5 !important;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #003561 !important;
}

.button {
  background-color: #002f6c !important;
  color: #fff !important;
  font-family: Replica-Regular, Arial, sans-serif !important;
  font-weight: 400 !important;
}

.button:hover {
  background: lightgray !important;
  color: black !important;
}

.ui.page.modals {
  z-index: 5000;
}
